<!-- Login View -->
<!-- @author Yasien Kashef -->
<!-- @version 2.0.0 -->
<template>
  <div class="view">
    <img class="body" src="@/assets/body.png" />
    <section>
      <form @submit.prevent="login()">
        <img class="logo" src="@/assets/logo.png" />
        <label for="name">First & Last Name</label>
        <input
          ref="name"
          id="name"
          name="name"
          type="text"
          autocomplete="name"
          autofocus
          maxlength="255"
          required
        />
        <label for="company">Company Name</label>
        <input
          ref="company"
          id="company"
          name="company"
          type="text"
          autocomplete="organization"
          maxlength="255"
          required
        />
        <label for="password">Password</label>
        <input
          ref="password"
          id="password"
          name="password"
          type="password"
          autocomplete="current-password"
          required
          @input="resetPlaceholder()"
        />
        <button :disabled="isSubmitting">
          <span v-if="!isSubmitting">LOGIN</span>
          <div v-else class="lds-dual-ring"></div>
        </button>
      </form>
      <p>
        This event contains forward-looking statements for purposes of the safe
        harbor<br />
        provisions of the Private Securities Litigation Reform Act of 1995.
        Forward-looking<br />
        statements include statements regarding intentions, beliefs,
        projections, outlook,<br />
        analyses or current expectations. Various factors may cause differences
        between<br />
        Allogene's expectations and actual results as discussed in greater
        detail in<br />
        Allogene's filings with the Securities and Exchange Commission (SEC).<br />
        The safety and efficacy for allogeneic CAR T investigational products<br />
        have not been established, and these investigational products are<br />
        not approved for commercial use by the FDA.
      </p>
    </section>
  </div>
</template>

<script>
/**
 * Login view
 * @author Yasien Kashef
 * @version 3.0.0
 */
import axios from "axios";

export default {
  name: "LoginView",
  data() {
    return {
      isSubmitting: false   // Is set to true while in process of submitting
    }
  },
  methods: {
    login() {
      this.isSubmitting = true;
      let password = this.$refs.password.value;

      // Check password
      axios.post("https://baermedia-digital-events.com/apc/baermedia/login-system/authenticate.php",
        new URLSearchParams({
          username: "",
          password: password,
          customer: "AllogeneTherapeutics",
          project: "cf1.com",
        }),
      )
      // If password is correct
      .then(async () => {
        // Set authentication cookie
        this.$cookies.set('auth', true);
        try {
          // Submit data to database & route to intro
          let response = await this.submitToDatabase();
          if(response.status < 300) this.$router.push({ name: 'IntroView', params: { type: 'vip' }});
        } catch (error) {
          console.error("An error has occured while submitting to database: "+error);
        }
      })
      // If password is wrong or any error has happened
      .catch((error) => {
        this.isSubmitting = false;
        this.$refs.password.value = "";
        this.$refs.password.placeholder = "Wrong password!";
        console.error("Wrong password: " + error);
      })
    },
    async submitToDatabase() {
      let response = await axios.post(`${this.$DOMAIN}/php/submit.php`,
        new URLSearchParams({
          name: this.$refs.name.value,
          company: this.$refs.company.value
        })
      );
      return response;
    },
    resetPlaceholder() {
      this.$refs.password.placeholder = "";
    }
  },
  mounted() {
    this.$cookies.remove("auth");
  },
};
</script>

<!-- @author Yasien Kashef -->
<!-- @version 1.0.0 -->
<style lang="scss" scoped>
.view {
  width: 100%;
  height: 100%;

  section {
    position: fixed;
    right: 8%;
    bottom: calc(5vw + 2vh);

    form {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      row-gap: 0.25em;
      font-size: clamp(1rem, 1.2vw, 1.4rem);
      font-weight: 300;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
      border-radius: 0.5em;
      padding: 3em 3.5em;

      img {
        width: 20em;
        max-width: 100%;
        margin-bottom: 7.5%;
      }
      input {
        width: 100%;
        padding: 0.3em;
        margin-bottom: 2.5%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.25s;

        &:focus {
          box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.2);
        }
        &::placeholder {
          color: red;
        }
      }
      button {
        font-weight: 400;
        font-size: clamp(1.1rem, 1.3vw, 1.5rem);
        padding: 0.8em 1.2em;
        color: white;
        background: linear-gradient(
          90deg,
          rgba(14, 104, 111, 1) 0%,
          rgba(47, 129, 135, 1) 100%
        );
        box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.2);
        border-radius: 0.75em;
        margin-top: 7.5%;
        cursor: pointer;
        transition: transform 0.25s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    p {
      margin: 2.5% auto;
      text-align: center;
      font-size: clamp(0.5rem, 0.6vw, 0.7rem);
      font-weight: 300;
      color: #cccccc;
    }
  }

  .body {
    height: 100%;
    position: fixed;
    bottom: -8%;
    transition: all 0.25s;
    pointer-events: none;
  }

  // Portrait
  @media screen and (max-width: 900px) {
    section {
      right: 5%;
      left: 5%;
      bottom: 25%;

      form {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.75) 100%
        );
        padding: 2em 2.5em;
        border-radius: 0.5em 0.5em 0 0;
      }
      p {
        margin: 0;
        padding: 2.5% 2.5%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.75) 100%
        );
        border-radius: 0 0 0.7em 0.7em;
      }
    }
    .body {
      height: 85%;
      width: 110%;
      left: -10%;
      bottom: -12%;
      object-fit: cover;
      object-position: 50% top;
    }
  }

  // Mobile Landscape
  @media screen and (max-width: 900px) and (orientation: landscape) {
    section {
      top: 5%;
      bottom: 5%;
      left: 15%;
      right: 15%;

      form {
        padding: 1em 1.5em;
        img {
          width: 10em;
          margin-bottom: 1%;
        }
        input {
          margin-bottom: 1%;
        }
        button {
          font-size: 1rem;
          margin-top: 1%;
        }
      }
      p {
        margin: 0;
        padding: 0 5% 1%;

        br {
          display: none;
        }
      }
    }
  }

  // Portrait Slim
  @media screen and (max-width: 390px) {
    br {
      display: none;
    }
  }

  .lds-dual-ring {
    display: inline;
    width: clamp(1.1rem, 1.3vw, 1.5rem);
    height: clamp(1.1rem, 1.3vw, 1.5rem);

    &:after {
      content: " ";
      display: block;
      width: clamp(1.1rem, 1.3vw, 1.5rem);
      height: clamp(1.1rem, 1.3vw, 1.5rem);
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
