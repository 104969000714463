/**
 * Router
 * @author Yasien Kashef
 * @version 4.1.0
 */
import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue';
import IntroView from '@/views/IntroView.vue';

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/intro/public'
    },
    {
      path: '/vip',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'LoginView',
      component: LoginView
    },
    {
      path: '/intro/:type',
      name: 'IntroView',
      component: IntroView
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})