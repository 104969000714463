/**
 * Main
 * @author Yasien Kashef
 * @version 4.0.0
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'

VueCookies.config('', '', 'cf1.com');

const APP = createApp(App);
APP.use(router).use(VueCookies).mount('#app');

// Setting global variables
const ENV = process.env.NODE_ENV;
let domain;
if(ENV === 'development') {
    domain = 'http://localhost:8081'
}
if(ENV === 'production') {
    domain = 'https://cf1.com'
}
APP.config.globalProperties.$DOMAIN = domain;

