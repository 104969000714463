<!-- App.vue -->
<!-- @author Yasien Kashef -->
<!-- @version 3.0.0 -->
<template>
  <div id="app">
    <router-view v-slot="{ Component }" :key="$route.path">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<!-- @author Yasien Kashef -->
<!-- @version 1.0.0 -->
<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
  color: white;
  font-family: 'Gotham', Avenir, Helvetica, Arial, sans-serif;
  font-weight: 300;
  background: white url("./assets/background.jpg") center / cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button, input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: none;
  outline: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
