<!-- Intro View -->
<!-- @author Yasien Kashef -->
<!-- @version 1.0.0 -->
<template>
  <div class="view" @click="cutRibbon()">
    <video ref="video" width="100%" height="100%" muted autoplay playsinline
      poster = "@/assets/intro/poster.jpeg"
      @timeupdate="isPlaying ? stopAtScissors() : false"
      @ended="openTour()">
      <source src="@/assets/intro/big.mp4" type="video/mp4" />
      <source src="@/assets/intro/small.mp4" type="video/mp4" />
    </video>
    <div class="hint" :style="{ opacity: isPlaying ? 0 : 1}">
      Click on the scissors to cut the ribbon
    </div>
  </div>
</template>

<script>
/**
 * Intro view
 * @author Yasien Kashef
 * @version 4.1.0
 */

export default {
  name: "IntroView",
  data() {
    return {
      isPlaying: true
    }
  },
  methods: {
    stopAtScissors() {
      let currentTime = this.$refs.video.currentTime;
      if(currentTime > 10) {
        this.$refs.video.pause();
        this.isPlaying = false;
      }
    },
    cutRibbon() {
      this.$refs.video.play();
    },
    /**
     * Decides to which tour to redirect, depending on the route param.
     * @since 3.0.0
     */
    openTour() {
      let typeOfTour = this.$route.params['type'];
      if(typeOfTour === 'vip') window.open("https://cf1.com/.tour/index.htm", "_self");
      else window.open("https://cf1.com/.tour-public/index.htm", "_self");
    }
  }
};
</script>

<!-- @author Yasien Kashef -->
<!-- @version 1.0.0 -->
<style lang="scss" scoped>
.view {
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 67%;
  }

  .hint {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2%;
    background-color: #165c6d;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    padding: 0.75em;
    border-radius: 0.5em;
    box-shadow: -2px 2px 5px 2px rgba(0, 0, 0, 0.5);
    transition: all 0.25s;
  }
}
</style>
